@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', sans-serif;
}

/* Custom spinner loader CSS */
.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #4caf50; /* Green */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
